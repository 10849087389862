<template>
  <svg class="svg-icon" aria-hidden="true">
    <use :xlink:href="iconName"></use>
  </svg>
</template>

<script>
import { computed } from "vue";
export default {
  name: "icon-svg",
  props: {
    iconClass: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    let iconName = computed(() => {
      return `#${props.iconClass}`;
    });
    console.log(iconName.value);
    return { iconName };
  },
};
</script>
<style type="text/css">
.svg-icon {
  width: 1.1em;
  height: 1.1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
</style>
