import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import qs from 'qs'

import NotFound from '../views/notFound'
const routes = [
  { path: '/:catchAll(.*)*', name: 'NotFound', component: NotFound },
  { path: '/', name: 'Home', component: Home },
  { path: '/login', name: 'login', component: () => import('../views/user/Login.vue') },
  { path: '/forgetPwd', name: 'forgetPwd', component: () => import('../views/user/ForgetPwd.vue') },
  { path: '/updatePwd', name: 'updatePwd', component: () => import('../views/user/UpdatePwd.vue') },
  { path: '/merchantSelect', name: 'merchantSelect', component: () => import('../views/user/MerchantSelect.vue') },
  { path: '/category', name: 'category', component: () => import('../views/category/category.vue') },
  { path: '/brands', name: 'brands', component: () => import('../views/brands/brands.vue') },
  { path: '/goodsSearch', name: 'goodsSearch', component: () => import('../views/goodsSearch/goodsSearch.vue') },
  { path: '/commoditySet', name: 'commoditySet', component: () => import('../views/commoditySet/index.vue') },
  { path: '/cases', name: 'cases', component: () => import('../views/cases/index.vue') },
  { path: '/caseDetails', name: 'caseDetails', component: () => import('../views/cases/caseDetails.vue') },
  { path: '/goodsDetails', name: 'goodsDetails', component: () => import('../views/goodsSearch/goodsDetails.vue') },
  { path: '/goodsShare', name: 'goodsShare', component: () => import('../views/goodsSearch/goodsShare.vue') },
  { path: '/goodsQr', name: 'goodsQr', component: () => import('../views/goodsSearch/goodsQr.vue') },
  { path: '/cart', name: 'cart', component: () => import('../views/cart/cart.vue') },
]

const router = createRouter({
  parseQuery: qs.parse,
  stringifyQuery: qs.stringify,
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
