import axios from 'axios'
import store from '../store'
import { Dialog } from 'vant';
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 10000
})

// request拦截器
service.interceptors.request.use(config => {
    return config
}, error => {
    Promise.reject(error);
})

// respone拦截器
service.interceptors.response.use(
    response => {
        // store.commit('hideLoading')
        const res = response.data;
        if (res.code === 200) {
            return Promise.resolve(res.response);
        } else if (res.code === 900 || res.code == 901) {
            Dialog.alert({
                message: '授权已过期,请退出重新登录'
            }).then(() => {
                store.dispatch('FedLogOut')
            });

        } else if (res.code === 500) {
            Dialog.alert({
                title: '错误',
                message: res.message,
            });
            return Promise.reject()
        }
    },
    error => {
        Dialog.alert({
            title: '错误',
            message: '当前服务不可用,请稍后尝试',
        });
        return Promise.reject(error);
    }
)

export default service