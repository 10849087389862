<template>
  <div class="case-item-container" @click="handleViewCommodity">
    <div class="case-pic">
      <img v-if="commodityInfo.coverPicUrl" :src="commodityInfo.coverPicUrl" />
      <img
        v-else
        src="https://wca.ujiaku.com/upload/app_defaultImg/noPic.png"
      />
    </div>
    <div class="case-name van-multi-ellipsis--l2">
      {{ commodityInfo.suitName }}
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, reactive, toRefs } from "vue";
import { useRouter } from "vue-router";
export default defineComponent({
  props: {
    commodityInfo: Object,
  },
  setup(props) {
    const router = useRouter();
    const handleViewCommodity = () => {
      console.log("handleViewCommodity============", props.commodityInfo);
      router.push({
        name: "commoditySet",
        query: {
          id: props.commodityInfo.pmsId,
          name: props.commodityInfo.suitName,
        },
      });
    };
    return {
      handleViewCommodity,
    };
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.case-item-container {
  text-align: left;
  background: #ffffff;
  .case-pic {
    img {
      width: 188px;
      height: 110px;
      object-fit: cover;
      border-radius: 8px;
    }
  }
  .case-name {
    color: #171725;
    width: 188px;
    font-weight: 500;
    font-size: 14px;
    text-align: left;
    margin-top: 12px;
  }
}
</style>
