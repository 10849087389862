import fetch from '@/utils/request';
export function getShopHomeInfo(param) {
    return fetch({
        url: '/project/shop/material/getShopHomeInfo',
        method: 'POST',
        data: param
    });
}

export function getSidebarCategoryInfo(param) {
    return fetch({
        url: '/project/shop/material/getSidebarCategoryInfo',
        method: 'POST',
        data: param
    });
}

export function getShopBrandList(param) {
    return fetch({
        url: '/project/shop/material/getShopBrandList',
        method: 'POST',
        data: param
    });
}

export function listShopMaterialByPage(param) {
    return fetch({
        url: '/project/material/mobile/listShopMaterialByPage',
        method: 'POST',
        data: param
    });
}

export function initGetMaterialList(param) {
    return fetch({
        url: '/project/material/mobile/initGetMaterialList',
        method: 'POST',
        data: param
    });
}

export function getSuitShop(param) {
    return fetch({
        url: '/project/materialSuit/getSuitShop',
        method: 'POST',
        data: param
    });
}
export function initVideoStyleInfo(param) {
    return fetch({
        url: '/project/shop/material/initVideoStyleInfo',
        method: 'POST',
        data: param
    });
}

export function getVideoStyleList(param) {
    return fetch({
        url: '/project/shop/material/getVideoStyleList',
        method: 'POST',
        data: param
    });
}

export function getShopMaterialDetail(param) {
    return fetch({
        url: '/project/material/mobile/getShopMaterialDetail',
        method: 'POST',
        data: param
    });
}
export function getShopMaterialWithoutToken(param) {
    return fetch({
        url: '/project/material/mobile/getShopMaterialWithoutToken',
        method: 'POST',
        data: param
    });
}


export function addMaterialToShopCart(param) {
    return fetch({
        url: '/project/shop/material/addMaterialToShopCart',
        method: 'POST',
        data: param
    });
}

export function editShopCartMaterialNumber(param) {
    return fetch({
        url: '/project/shop/material/editShopCartMaterialNumber',
        method: 'POST',
        data: param
    });
}

export function deleteShopCartMaterial(param) {
    return fetch({
        url: '/project/shop/material/deleteShopCartMaterial',
        method: 'POST',
        data: param
    });
}

export function clearShopCartMaterial(param) {
    return fetch({
        url: '/project/shop/material/clearShopCartMaterial',
        method: 'POST',
        data: param
    });
}
export function getShopCartMaterialInfo(param) {
    return fetch({
        url: '/project/shop/material/getShopCartMaterialInfo',
        method: 'POST',
        data: param
    });
}
export function calculateMaterialofShopCart(param) {
    return fetch({
        url: '/project/shop/material/calculateMaterialofShopCart',
        method: 'POST',
        data: param
    });
}
export function clearCartDeleteMaterial(param) {
    return fetch({
        url: '/project/shop/material/clearCartDeleteMaterial',
        method: 'POST',
        data: param
    });
}


