<template>
  <swiper v-bind="{ ...swiper_options, ...options }" effect="coverflow">
    <swiper-slide
      :style="[slideStyle]"
      v-for="item in imgs"
      :key="item.id"
      class="slide-item"
    >
      <slot name="default" :item="item"></slot>
    </swiper-slide>
  </swiper>
</template>
<script>
import { reactive } from "vue";
// 使用swiper的compositon API
import SwiperCore, { Autoplay, EffectCoverflow } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.scss";
SwiperCore.use([Autoplay, EffectCoverflow]);
export default {
  name: "MySwiper",
  props: {
    imgs: {
      type: Array,
      default() {
        return [];
      },
    },
    slideStyle: {
      type: Object,
      default() {
        return {};
      },
    },
    options: {
      type: Object,
      default() {
        return {};
      },
    },
    width: {
      type: String,
      default() {
        return "176px";
      },
    },
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  setup(props) {
    // swiper相关配置属性放在swiper_options这个变量里
    let swiper_options = reactive({
      autoplay: {
        disableOnInteraction: false, // 鼠标滑动后继续自动播放
        delay: 2000, // 2秒切换一次
      },
      speed: 400, //切换过渡速度
      loop: false,
      slidesPerView: "auto", //解决最后一张切换到第一张中间的空白
      // loop: true,
      // slidesPerView: 'auto',
      // loopedSlides: 8,
      centeredSlides: false, //设置slide居中
      spaceBetween: 0,
      coverflowEffect: {
        rotate: 0, //slide做3d旋转时Y轴的旋转角度。默认50。
        stretch: -10, //每个slide之间的拉伸值（距离），越大slide靠得越紧。 默认0。
        depth: 0, //slide的位置深度。值越大z轴距离越远，看起来越小。 默认100。
        modifier: 1, //depth和rotate和stretch的倍率，相当于            depth*modifier、rotate*modifier、stretch*modifier，值越大这三个参数的效果越明显。默认1。
        slideShadows: false, //开启slide阴影。默认 true。
      },
    });
    // 将swiper_options返回给模板中的swiper组件使用
    return { swiper_options };
  },
};
</script>
<style lang="scss" scoped var="{width}">
.slide-item {
  width: var(--width);
  margin-left: 4px;
  &:last-child {
    margin-right: 4px;
  }
}
</style>


