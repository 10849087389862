import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
//桌面端适配
import '@vant/touch-emulator';
//解决适配问题
import 'amfe-flexible/index.js'
//去除谷歌浏览器对阻止touch事件默认行为的警告提示
import 'default-passive-events';
import '@/assets/iconfont'
//引入vant组件库
import Vant from 'vant';
import 'vant/lib/index.less';
import iconSvg from '@/components/IconSvg'
import Video from 'video.js'
import 'video.js/dist/video-js.css'

import './assets/font/font.css'
import { isNavigationFailure } from 'vue-router'

const whiteList = ['/login', '/forgetPwd', '/updatePwd', '/merchantSelect', '/goodsShare']
import { getShopCartMaterialInfo } from '@/api/common'

router.beforeEach(async (to, from, next) => {
    const token = store.getters.access_token
    const materialId = store.getters.materialId
    if (whiteList.includes(to.path)) {
        if (to.path === '/login' && token) {
            next('/')
            return
        }
        next();
        return;
    }

    if (!token) {
        next('/login');
        return
    }
    if (!materialId) {
        next('/merchantSelect');
        return
    }
    if (store.getters.isReloadUserInfo) {
        next();
        return;
    }
    await store.dispatch('GetInfo');
    const redirectPath = (from.query?.redirect || to.path);
    const redirect = decodeURIComponent(redirectPath);
    const nextData = to.path === redirect ? { ...to, replace: true } : { path: redirect };
    store.commit('SET_ISRELOADUSERINFO', true)
    next(nextData);
})

router.afterEach(async (to, from, failure) => {
    if (isNavigationFailure(failure)) {
        console.log('failed navigation', failure)
    }
    const token = store.getters.access_token
    const materialId = store.getters.materialId
    if (token && materialId) {
        let data = await getShopCartMaterialInfo({
            token: token,
            materialStockId: materialId,
        });
        if (data.singleMaterialList?.length || data.suitMaterialList?.length) {
            store.commit('SET_EMPTYCART', false)
        } else {
            store.commit('SET_EMPTYCART', true)
        }
    }
  
})

const app = createApp(App)
app.use(store).use(router).use(Vant).mount('#app')
app.component('icon-svg', iconSvg)
app.config.globalProperties.$video = Video