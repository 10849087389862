<template>
  <Loadings v-show="loading" />
  <router-view> </router-view>
</template>
<script>
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import Loadings from "@/components/Loading";
export default defineComponent({
  components: {
    Loadings,
  },
  setup() {
    const store = useStore();
    const loading = computed(() => store.getters.loading);
    return {
      loading,
    };
  },
});
</script>
<style lang="scss">
* {
  margin: 0;
  padding: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  width: 100%;
  height: 100%;
  text-align: center;
}
::-webkit-scrollbar {
  width: 0 !important;
}
::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}
#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
