const app = {
    state: {
        loading: false,
        emptyCart: false,
    },
    mutations: {
        showLoading: (state) => {
            state.loading = true
        },
        hideLoading: (state) => {
            state.loading = false
        },
        SET_EMPTYCART: (state, val) => {
            state.emptyCart = val
        }
    },
    actions: {}
}

export default app;