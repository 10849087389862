import fetch from '@/utils/request';

export function login(phone, userPwd, typeId) {
  return fetch({
    url: '/user/login',
    method: 'POST',
    data: {
      phone,
      userPwd,
      typeId,
      ujiabao: "ujiabao"
    }
  });
}

export function logout(token) {
  return fetch({
    url: '/user/logout',
    method: 'post',
    data: { token }
  });
}

export function getUserVerificationCode(params) {
  return fetch({
    url: '/user/modifyPwd/getUserVerificationCode',
    method: 'post',
    data: {
      ...params
    }
  });
}

export function resetPwd(params) {
  return fetch({
    url: '/user/resetPwd',
    method: 'post',
    data: {
      ...params
    }
  });
}
/**获取信息
 * */
export function getInfo(param) {
  return fetch({
    url: '/user/getUserSessionInfo ',
    method: 'POST',
    data: param
  });
}


export function indexMyMerchant(param) {
  return fetch({
    url: '/user/merchant/indexMyMerchant',
    method: 'POST',
    data: param
  });
}

export function enterMerchant(param) {
  return fetch({
    url: '/user/merchant/enterMerchant',
    method: 'POST',
    data: param
  });
}

export function initMaterialInfoByMerchant(param) {
  return fetch({
    url: '/project/shop/material/initMaterialInfoByMerchant',
    method: 'POST',
    data: param
  });
}
