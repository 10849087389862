<template>
  <div class="goods-item-container" @click="handleViewDetails">
    <div class="goods-pic">
      <img v-if="goodInfo.materialPicUrl" :src="goodInfo.materialPicUrl" />
      <img
        v-else
        src="https://wca.ujiaku.com/upload/app_defaultImg/noPic.png"
      />
    </div>
    <div class="head-sales" v-if="headSales">
      热销{{ nums }}{{ goodInfo.salesUnitName }}
    </div>
    <div class="info-mian">
      <div class="goods-name van-ellipsis">
        {{ goodInfo.materialName }}
      </div>
      <div class="goods-price">
        <span>¥</span>
        <span>{{ goodInfo.sellingPrice.split(".")[0] }}</span>
        <span v-show="goodInfo.sellingPrice.split('.')[1]"
          >.{{ goodInfo.sellingPrice.split(".")[1] }}</span
        >
        <span v-if="!headSales">已售{{ nums }}</span>
      </div>
      <div class="goods-bottom">
        <div class="goods-tag">
          <span>热门</span>
        </div>
        <div class="cart" style="align-self:flex-end">
          <van-icon
            v-if="selfClik === true"
            name="cart-o"
            size="20"
            @click.stop="handleInitAddToCart"
          />
        </div>
      </div>
    </div>
  </div>
  <van-popup
    round
    :show="show"
    :close-on-click-overlay="false"
    @click-overlay="show = false"
    teleport="body"
    position="bottom"
  >
    <div class="cart-wrapper">
      <div class="cart-main">
        <div class="cart-head-box">
          <div class="pic">
            <img
              v-if="goodInfo.materialPicUrl"
              :src="goodInfo.materialPicUrl"
            />
            <img
              v-else
              src="https://wca.ujiaku.com/upload/app_defaultImg/noPic.png"
            />
          </div>
          <div class="description">
            <div class="description-title">
              {{ goodInfo.materialName }} {{ goodInfo.itemNo }}
            </div>
            <div class="spec">规格 {{ selectedSpec }}</div>
            <div class="price">￥ {{ goodInfo.sellingPrice }}</div>
          </div>
        </div>
        <div class="spec-select" v-if="goodInfo.specs">
          <div>规格分类</div>
          <div style="margin-top:10px">
            <span
              v-for="(item, index) in [goodInfo.specs]"
              :key="index"
              :class="selectedSpec == item ? 'active' : 'Classification'"
              @click="oncheck(item)"
            >
              {{ item }}</span
            >
          </div>
        </div>
        <div class="number-check">
          <div>
            数量{{ goodInfo.salesUnitName ? "/" + goodInfo.salesUnitName : "" }}
          </div>
          <van-stepper v-model="number" min="1" disable-input />
        </div>
      </div>
      <div class="bottom-btns">
        <van-button
          block
          round
          style="width:60%;margin:0 auto;"
          type="primary"
          @click="handleAddToCart"
          >确定</van-button
        >
      </div>
    </div>
  </van-popup>
</template>

<script>
import { Dialog, Toast } from "vant";
import { computed, defineComponent, reactive, toRefs } from "vue";
import { useStore } from "vuex";
import { addMaterialToShopCart } from "../api/common";
import router from "../router";
export default defineComponent({
  props: {
    goodInfo: {
      type: Object,
    },
    selfClik: {
      type: Boolean,
      default: true,
    },
    headSales: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["click-item"],
  setup(props, { emit }) {
    const state = reactive({
      nums: "",
      selectedSpec: "",
      show: false,
      number: "",
    });
    const store = useStore();
    const token = computed(() => store.getters.token);
    const materialId = computed(() => store.getters.materialId);
    const merchantId = computed(() => store.getters.merchantId);
    //生成三位随机数
    for (var i = 0; i < 4; i++) {
      state.nums += Math.floor(Math.random() * 10);
    }
    if (Number(state.nums) < 1000) {
      state.nums = Number((state.nums += 1));
    }
    const handleInitAddToCart = () => {
      state.selectedSpec = props.goodInfo.specs
      state.show = true;
    };
    const handleAddToCart = () => {
      if (props.goodInfo.specs && !state.selectedSpec) {
        Dialog.alert({
          message: "请选择规格",
          title: "错误",
        });
        return false;
      } else {
        let param = {
          token: token.value,
          materialStockId: materialId.value,
          materialId: props.goodInfo.materialId,
          number: state.number,
        };
        addMaterialToShopCart(param).then((data) => {
          if (data) {
            state.show = false;
            Toast.success({ message: "加入购物车成功" });
            store.commit("SET_EMPTYCART", false);
          }
        });
      }
    };
    const oncheck = (item) => {
      state.selectedSpec
        ? (state.selectedSpec = "")
        : (state.selectedSpec = item);
    };

    const handleViewDetails = () => {
      if (props.selfClik == false) {
        console.log("props.goodInfo.materialId====", props.goodInfo.materialId);
        emit("click-item", props.goodInfo.materialId);
      } else {
        router.push({
          name: "goodsDetails",
          query: { id: props.goodInfo.materialId },
        });
      }
    };

    return {
      ...toRefs(state),
      handleInitAddToCart,
      handleAddToCart,
      oncheck,
      handleViewDetails,
    };
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
* {
  padding: 0;
  margin: 0;
}
.goods-item-container {
  text-align: left;
  border-radius: 8px;
  background: #ffffff;
  width: 171px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05);
  .head-sales {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 8px;
    background: #fe3a30;
    border-radius: 4px;
    color: #ffffff;
    font-weight: 600;
    font-size: 11px;
    width: 65px;
    margin: 12px auto 4px;
    height: 16px;
  }
  .info-mian {
    padding: 8px;
  }
  .goods-pic {
    width: 171px;
    height: 171px;
    img {
      width: 171px;
      height: 171px;
      object-fit: contain;
      border-radius: 8px 8px 0px 0px;
    }
  }
  .goods-name {
    color: #171725;
    text-align: left;
    height: 20px;
    line-height: 20px;
    font-weight: 500;
    font-size: 14px;
  }
  .goods-tag {
    text-align: center;
    width: 24px;
    height: 16px;
    color: #fe3a30;
    font-size: 11px;
    padding: 1px 4px;
    border-radius: 4px;
    border: 1px solid #fe3a30;
  }
  .goods-price {
    margin: 12px 0 8px 0px;
    font-size: 12px;
    span:nth-of-type(1) {
      font-family: DIN;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 12px;
      color: #fe3a30;
    }
    span:nth-of-type(2) {
      font-weight: 500;
      font-size: 18px;
      line-height: 18px;
      color: #fe3a30;
      font-family: DIN;
    }
    span:nth-of-type(3) {
      font-family: DIN;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 12px;
      color: #fe3a30;
    }
    span:nth-of-type(4) {
      color: #92929d;
      font-size: 8px;
      margin-left: 8px;
    }
  }
  .goods-bottom {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
  }
}
.cart-wrapper {
  padding: 16px;
  min-height: 450px;
  display: flex;
  flex-direction: column;
  text-align: left;
  .cart-main {
    flex: 1;
    .cart-head-box {
      display: flex;
      .pic {
        margin-right: 10px;
        img {
          width: 124px;
          height: 124px;
          object-fit: contain;
        }
      }

      .description-title {
        color: #171725;
        font-weight: 500;
        font-size: 16px;
      }
      .spec {
        font-size: 12px;
        color: #696974;
        margin: 10px 0px;
      }
      .price {
        color: #696974;
        font-size: 14px;
      }
    }
    .spec-select {
      color: #696974;
      font-size: 14px;
      &::after {
        content: ""; /*设置内容为空*/
        height: 0; /*高度为0*/
        line-height: 0; /*行高为0*/
        display: block; /*将文本转为块级元素*/
        visibility: hidden; /*将元素隐藏*/
        clear: both;
      }
    }
    .number-check {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      color: #696974;
      font-size: 14px;
    }
  }
}
.active {
  float: left;
  padding: 4px 12px;
  background: #d6e4ff;
  margin-bottom: 10px;
  border-radius: 16px;
  font-size: 14px;
  color: #4d6ff0;
}
.Classification {
  float: left;
  padding: 4px 12px;
  background: #f7f7f7;
  margin-bottom: 10px;
  border-radius: 16px;
  font-size: 14px;
}
</style>
