const getters = {
    access_token: state => state.user.access_token,
    session: state => state.user.session,
    token: state => state.user.access_token,
    refresh_token: state => state.user.refresh_token,
    loading: state => state.app.loading,
    isReloadUserInfo: state => state.user.isReloadUserInfo,
    merchantId: state => state.user.merchantId,
    merchantName: state => state.user.merchantName,
    materialId: state => state.user.materialId,
    userName: state => state.user.userName,
    emptyCart: state => state.app.emptyCart,
}
export default getters