<template>
  <van-tabbar route active-color="#4D6FF0" inactive-color="#171725">
    <van-tabbar-item icon="wap-home-o" to="/">首页</van-tabbar-item>
    <van-tabbar-item icon="apps-o" to="/category">分类</van-tabbar-item>
    <van-tabbar-item icon="gem-o" to="/brands">品牌</van-tabbar-item>
    <van-tabbar-item icon="video-o" to="/cases">案例</van-tabbar-item>
    <van-tabbar-item icon="shopping-cart-o" :dot="dot" to="/cart"
      >购物车</van-tabbar-item
    >
  </van-tabbar>
</template>

<script setup>
import { computed } from "@vue/reactivity";
import { useStore } from "vuex";
const store = useStore();
const dot = computed(() => {
  return !store.getters.emptyCart;
});
</script>

<style lang="scss" scoped></style>
